import React from 'react';
import type { FC } from 'react';
import { Link, graphql } from 'gatsby';
import { Head } from '../components/head';
import { Layout } from '../components/layout';
import type { AboutPageQuery } from 'graphql-types';

interface PageProps {
  data: AboutPageQuery;
}

const Page: FC<PageProps> = ({ data }) => {
  return (
    <Layout articleTitle={data.site?.siteMetadata?.author ?? ''}>
      <h2>{data.site?.siteMetadata?.author ?? ''}について</h2>
      <dl>
        <dt>所在地</dt>
        <dd>
          〒135-0091 東京都港区台場１丁目３番１号 都営台場一丁目アパート１号棟
        </dd>
      </dl>
      <Link to="/">戻る</Link>
    </Layout>
  );
};
export default Page;

export const query = graphql`
  query AboutPage {
    site {
      siteMetadata {
        title
        author
      }
    }
  }
`;
